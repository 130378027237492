import React from 'react'
import Layout from '../../staticcomponents/layout'
import './why-cartus.scss'
import WhyCartusHeader from './why-cartus-header'
import OurProudAssociation from './our-proud-association'
import OurProudLogo from './our-proud-logo'
import OurKeyTab from './our-key-tab'
import OurWinningMoments from './our-winning-moments'
import JoinOurTeam from './join-our-team'

export default function WhyCartus() {
    return (
        <>
            <Layout>
                <div className='why-cartus-div-cls'>
                <div className="bg-left-donut">
                    <WhyCartusHeader />
                    <OurProudAssociation />
                    <OurProudLogo />
                    
                    <OurKeyTab />
                   
                </div>
                <div className='capsules-bg'>
                <OurWinningMoments />
                </div>
                <JoinOurTeam />
                </div>
            </Layout>
        </>
    )
}