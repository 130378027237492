import React from "react";
import './our-proud-logo.scss';
import frank from 'assets/frank-cartus.png';
import gamegolf from 'assets/game-golf-cartus.png';
import globalcartus from 'assets/global-cartus.png';
import university from 'assets/university-cartus.png';
import publix from 'assets/publix-cartus.png';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import ImageSlider from '../../../staticcomponents/image-slider/index';


export default function OurProudLogo() {
  const ImgArray = [university, frank, gamegolf, globalcartus, publix]
  return (
    <>
      <Container maxWidth="xl" className='zero-padding-div'>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="our-cartus-cls">
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2}>
            <Grid item xl={4} lg={4} md={5} sm={6} xs={8}>
              <h2 className='title'> our proud logo</h2>
            </Grid>
          </Grid>
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <h3 className="sub-title">moving people who move the world</h3>
            </Grid>
          </Grid>
          <ImageSlider ImageArray={ImgArray} />
        </Grid>
      </Container>
    </>
  )
}


