import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloseIcon from "@material-ui/icons/Close";
import { Button, Dialog, DialogContent, Slide, IconButton, Box } from '@material-ui/core';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import whycartus_heroimg from '../../../assets/whycartus-heroimg.png';
import whycartus_video_thumbnail from '../../../assets/whycartus-video-thumbnail.png';
import './why-cartus-header.scss';
import Container from '@material-ui/core/Container';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function WhyCartusHeader() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
     <div className='why-cartus-hero'>
      {/* <grid for image background> */}
      <Grid className='header-div'>
        <Grid container className='header-container'>
          <Grid item xs={12} sm={12} md={12} lg={10} xl={10} className='header-hero-bg'>       
            <div className='blue-round-div'>
              <h1 className='title'>why cartus</h1>
              <p className='blue-sec-desp'>whether you’re designing your first mobility program or relocating thousands of executives people each year, cartus delivers proactive dynamic, customized flexible solutions that meet your unique needs, everytime.</p>
            </div>
          </Grid>
        </Grid>
        </Grid>
      </div>

      {/* <about us solutions section> */}
      <Container maxWidth="xl" className='zero-padding-div '>
      <Grid container className='why-cartus-txt-with-video-container'>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} className='header-intro-div'>
        <Grid item xs={6} sm={5} md={5} lg={7} xl={6}>
            <h2 className='title'>why employers choose cartus</h2>
          </Grid>          
          <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
              <h3 className='sub-title'>cartus = trust</h3>
              <p className='description'>Whether you’re designing your first mobility program or relocating thousands of executives people each year, Cartus delivers proactive dynamic, customized flexible solutions that meet your unique needs, everytime.</p>
              <p className='description'>It’s why we guide your employees through life’s biggest changes - home-finding assistance, intercultural and language training, settling-in services.</p>
              <p className='description'>Maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. Sed eget diam eu urna hendrerit euismod eu et nulla. Etiam consectetur maximus porta. Cras sit amet efficitur tortor, at placerat tortor.</p>
              <Button className='our-solutions-btn'>our solutions</Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
              <div className='image-box'>
                <Paper elevation={0} className="video-content">
                  <Button className="button-container" onClick={handleClickOpen}>
                    <img src={whycartus_video_thumbnail}
                      alt="video"
                      className="video-img" />
                    <PlayCircleIcon fontSize="large" className="play-button-icon" />
                  </Button>
                  <Dialog open={open} TransitionComponent={Transition} onClose={handleClose} maxWidth="md">
                    <Box className="popup-overlay">
                      <IconButton className="popup-close-icon" onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                      <DialogContent >
                        <Grid container className='dialog-content'>
                          <div className="iframe-class">
                            <iframe
                              width="560"
                              height="349"
                              className="responsive-iframe"
                              src="https://cartus.qumucloud.com/view/LAPdobvOgaG?autoPlay=true&embed=true&host=widgets&iframeName=kv-video-player-1-iframe-9247717&autoscale=true&AppProperties.PlayerShowEmbedButton=false" />
                          </div>
                        </Grid>
                      </DialogContent>
                    </Box>
                  </Dialog>
                  <Grid>
                    <Paper elevation={0}>
                      <Typography className="video-title">our solutions</Typography>
                      <Typography className="video-subtitle">how cartus helped magnolia sheia</Typography>
                    </Paper>
                  </Grid>

                </Paper>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </Container>
    </>
  )
}


