import React from "react";
import './our-winning-moments.scss'
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import Typography from '@material-ui/core/Typography';
import JewelsThumb1 from 'assets/JewelsThumb1.png';
import JewelsThumb2 from 'assets/JewelsThumb2.png';
import JewelsThumb3 from 'assets/JewelsThumb3.png';
import JewelsThumb4 from 'assets/JewelsThumb4.png';
import JewelsThumb5 from 'assets/JewelsThumb5.png';
import expertise_icon from '../../../assets/unrelenting-icon.png';
import Container from '@material-ui/core/Container';
import ImageSlider from '../../../staticcomponents/image-slider/index';


export default function OurWinningMoments() {
  const ImgArray= [JewelsThumb1,JewelsThumb2,JewelsThumb3,JewelsThumb4,JewelsThumb5]
  return (
    <>
      <Container maxWidth="xl" className='zero-padding-div'>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="our-winning-momnt-cls">
          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <h2 className="heading">
                <img src={expertise_icon} alt="expertise icon" className='arrow-img' /> <span>our winning moments</span></h2>
              <h3 className="sub-title">all said, the real glory is to fulfil goals all associated employees</h3>
            </Grid>
            {/* <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="capsules-bg"></Grid> */}
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
            <ImageSlider ImageArray={ImgArray}/>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}


