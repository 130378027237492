import React, { useRef, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloseIcon from "@material-ui/icons/Close";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Button, Dialog, DialogContent, Slide, IconButton, Box } from '@material-ui/core';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import './our-key-tab.scss';
import suppliment_img from '../../../assets/Suppliment.png';
import Container from '@material-ui/core/Container';

export default function OurKeyTab() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
<Container maxWidth="xl" className='zero-padding-div '>
      {/* our winning behaviors */}
      <Grid container spacing={1} className="whycartus-our-key">
        <Grid item container xl={4} lg={4} md={6} sm={7} xs={7}>
          <h2 className='title'>our key differentiators</h2>
        </Grid>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="txt-with-blue-section">
          <div className="txt-with-blue-section-overlay"></div>

          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} spacing={2} className='rich-text-div'>
            <Box sx={{ width: '100%', }}>
              <TabContext value={value} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable prevent tabs example"
                    className='ourkey-tabs'>
                    <Tab label="insignia" value="1" />
                    <Tab label="movepro 360" value="2" />
                    <Tab label="flexible mobility" value="3" />
                    <Tab label="DE&I" value="4" />
                    <Tab label="sustainability" value="5" />
                    <Tab label="dummy" value="6" />
                    <Tab label="dummy" value="7" />
                    <Tab label="dummy" value="8" />
                    <Tab label="dummy" value="9" />
                  </Tabs>
                </Box>
                <TabPanel value="1" className="ourkey-padding">
                  <Grid item container>
                    <Grid item xl={7} lg={7} md={7} sm={12} xs={12} spacing={2} className='ourkey-tabcontext'>
                      <p>maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. sed eget diam eu urna hendrerit euismod eu et nulla. etiam consectetur maximus porta. cras sit amet efficitur tortor, at placerat tortor. curabitur in quam eu justo viverra lobortis. suspendisse consectetur venenatis neque, quis vehicula augue pretium id. sed finibus pretium tortor sagittis placerat. sed vitae libero vestibulum, dictum turpis in, lobortis libero. in commodo vitae diam vel scelerisque. maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. sed eget diam eu urna hendrerit euismod eu et nulla. etiam consectetur maximus porta.</p>
                      <Button className='button-tab'>learn more</Button>
                    </Grid>
                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12} spacing={2}>
                      <div className='ourkey-imgtab'><img src={suppliment_img} alt="img" className='ourkey-img' />
                        <p className='img-subtitle'>Download</p>
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="2" className="ourkey-padding">
                <Grid item container>
                    <Grid item xl={7} lg={7} md={7} sm={12} xs={12} spacing={2} className='ourkey-tabcontext'>
                      <p>maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. sed eget diam eu urna hendrerit euismod eu et nulla. etiam consectetur maximus porta. cras sit amet efficitur tortor, at placerat tortor. curabitur in quam eu justo viverra lobortis. suspendisse consectetur venenatis neque, quis vehicula augue pretium id. sed finibus pretium tortor sagittis placerat. sed vitae libero vestibulum, dictum turpis in, lobortis libero. in commodo vitae diam vel scelerisque. maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. sed eget diam eu urna hendrerit euismod eu et nulla. etiam consectetur maximus porta.</p>
                      <Button className='button-tab'>learn more</Button>
                    </Grid>
                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12} spacing={2}>
                      <div className='ourkey-imgtab'><img src={suppliment_img} alt="img" className='ourkey-img' />
                        <p className='img-subtitle'>Download</p>
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="3" className="ourkey-padding">
                <Grid item container>
                    <Grid item xl={7} lg={7} md={7} sm={12} xs={12} spacing={2} className='ourkey-tabcontext'>
                      <p>maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. sed eget diam eu urna hendrerit euismod eu et nulla. etiam consectetur maximus porta. cras sit amet efficitur tortor, at placerat tortor. curabitur in quam eu justo viverra lobortis. suspendisse consectetur venenatis neque, quis vehicula augue pretium id. sed finibus pretium tortor sagittis placerat. sed vitae libero vestibulum, dictum turpis in, lobortis libero. in commodo vitae diam vel scelerisque. maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. sed eget diam eu urna hendrerit euismod eu et nulla. etiam consectetur maximus porta.</p>
                      <Button className='button-tab'>learn more</Button>
                    </Grid>
                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12} spacing={2}>
                      <div className='ourkey-imgtab'><img src={suppliment_img} alt="img" className='ourkey-img' />
                        <p className='img-subtitle'>Download</p>
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="4" className="ourkey-padding">
                <Grid item container>
                    <Grid item xl={7} lg={7} md={7} sm={12} xs={12} spacing={2} className='ourkey-tabcontext'>
                      <p>maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. sed eget diam eu urna hendrerit euismod eu et nulla. etiam consectetur maximus porta. cras sit amet efficitur tortor, at placerat tortor. curabitur in quam eu justo viverra lobortis. suspendisse consectetur venenatis neque, quis vehicula augue pretium id. sed finibus pretium tortor sagittis placerat. sed vitae libero vestibulum, dictum turpis in, lobortis libero. in commodo vitae diam vel scelerisque. maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. sed eget diam eu urna hendrerit euismod eu et nulla. etiam consectetur maximus porta.</p>
                      <Button className='button-tab'>learn more</Button>
                    </Grid>
                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12} spacing={2}>
                      <div className='ourkey-imgtab'><img src={suppliment_img} alt="img" className='ourkey-img' />
                        <p className='img-subtitle'>Download</p>
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="5" className="ourkey-padding">
                <Grid item container>
                    <Grid item xl={7} lg={7} md={7} sm={12} xs={12} spacing={2} className='ourkey-tabcontext'>
                      <p>maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. sed eget diam eu urna hendrerit euismod eu et nulla. etiam consectetur maximus porta. cras sit amet efficitur tortor, at placerat tortor. curabitur in quam eu justo viverra lobortis. suspendisse consectetur venenatis neque, quis vehicula augue pretium id. sed finibus pretium tortor sagittis placerat. sed vitae libero vestibulum, dictum turpis in, lobortis libero. in commodo vitae diam vel scelerisque. maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. sed eget diam eu urna hendrerit euismod eu et nulla. etiam consectetur maximus porta.</p>
                      <Button className='button-tab'>learn more</Button>
                    </Grid>
                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12} spacing={2}>
                      <div className='ourkey-imgtab'><img src={suppliment_img} alt="img" className='ourkey-img' />
                        <p className='img-subtitle'>Download</p>
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="6" className="ourkey-padding">
                <Grid item container>
                    <Grid item xl={7} lg={7} md={7} sm={12} xs={12} spacing={2} className='ourkey-tabcontext'>
                      <p>maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. sed eget diam eu urna hendrerit euismod eu et nulla. etiam consectetur maximus porta. cras sit amet efficitur tortor, at placerat tortor. curabitur in quam eu justo viverra lobortis. suspendisse consectetur venenatis neque, quis vehicula augue pretium id. sed finibus pretium tortor sagittis placerat. sed vitae libero vestibulum, dictum turpis in, lobortis libero. in commodo vitae diam vel scelerisque. maecenas vestibulum felis in lacus pharetra, eget laoreet purus lobortis. sed eget diam eu urna hendrerit euismod eu et nulla. etiam consectetur maximus porta.</p>
                      <Button className='button-tab'>learn more</Button>
                    </Grid>
                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12} spacing={2}>
                      <div className='ourkey-imgtab'><img src={suppliment_img} alt="img" className='ourkey-img' />
                        <p className='img-subtitle'>Download</p>
                      </div>
                    </Grid>
                  </Grid>
                </TabPanel>
              </TabContext>
            </Box>

          </Grid>

        </Grid>

      </Grid>
      </Container>
    </>
  )
}


